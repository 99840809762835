@use "sass:map";

$theme-colors: (
        "primary": #123123,
        "info": #1f5039,
        "link": #1f5039,
        "danger": #f05030
);

@import "~bootstrap/scss/bootstrap.scss";
@import "spinner.css";

* {
  //border: 1px red solid;
}

body {
  background: #f0f0f0;
  color: #111;
}

a {
  color: map.get($theme-colors, "link");
}

.divider {
  background-color: map.get($theme-colors, "primary");
  width: 110%;
  height: 5px;
  margin: 5px -5%;
  border-radius: 5px;
}

.match-divider {
  background-color: map.get($theme-colors, "primary");
  width: 90%;
  height: 3px;
  margin: 5px 3%;
  border-radius: 10px;
}

.text-green {
  color: green;
}

.text-orange {
  color: orange;
}

.text-red {
  color: red;
}

.checkbox {
  width: 20px;
  height: 20px;
  padding: 0;

  border: solid 1px map.get($theme-colors, "primary");
  border-radius: 3px;
}

.checkbox-checked {
  background-color: map.get($theme-colors, "primary")
}

.checkbox-disabled {
  background-color: rgba(0,0,0,0.1)
}

.checkbox-checked-disabled {
  background-color: rgba(18,49,35,0.75)
}

.chart-container {
  max-height: 500px;
  height: 500px;
}