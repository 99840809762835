/* https://10015.io/tools/css-loader-generator */

.spinner {
    margin: 30px auto;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    box-shadow: 36px 0 0 0 rgba(18, 49, 35, 0.2), 29px 21px 0 0 rgba(18, 49, 35, 0.4), 11px 34px 0 0 rgba(18, 49, 35, 0.6), -11.16px 34px 0 0 rgba(18, 49, 35, 0.8), -29.2px 21px 0 0 #123123;
    animation: spinner-rotate 0.8s infinite linear;
}

@keyframes spinner-rotate {
    to {
        transform: rotate(360deg);
    }
}